
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.add-blog .ql-tooltip.ql-editing {
left: 0 !important;
top: 81px !important;
margin: 0 !important;
}

.add-blog .ql-editor.ql-blank::before {
  font-style: normal;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #a5abb7;
}

.add-blog .ql-container{
  height: 90px;
}
.add-blog .ql-tooltip {
  margin: 0 !important;
  top: 81px !important;
  left: 1px !important;
}

.var-list .rdt_TableCell {
  font-weight: 500;
  font-size: 14px;
}
.blog-description-edit .ql-editor {
  max-height: 130px;
  overflow-y: scroll;
  min-height: 45px;
}
.blog-description-data .data-table
 .sc-fqkvVR.sc-dcJsrY.sc-iGgWBj.dKzMcW.ikexHN.hgByPU.rdt_TableCell {
  display: grid;
}
.blog-description-data .data-table
 .sc-fqkvVR.sc-dcJsrY.sc-iGgWBj.ffYoNC.rPOCv.krVTia.rdt_TableCell {
  display: grid;
}
input::-ms-reveal, input::-ms-clear{display: none;}

/* .blog-pagination .blog-description-data .data-table .rdt_TableBody {
  overflow-y: scroll;
  max-height: 66vh;
} */



/* Expenses page css */

.expenses input {
  max-width: 547px;
  width: 100%;
  border: 1px solid #ccc;
  height: 46px;
  border-radius: 5px;
  padding: 2px 12px;

}
.expenses label {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.expenses.name-box {
  display: flex;
    justify-content: space-between;
align-items: center;
} 
.expenses {
  /* box-shadow: 0px 0px 4px 1px #cccccc8c; */
  border-radius: 8px;
  background-color: #fff;
  padding: 20px 24px;
  border-left: 4px solid #ccc;
}
.expenses.name-box button.btn-plus {
  width: 40px;
  height: 40px;
  background-color: #eb9000;
  color: #fff;
  font-weight: 600;
  border-radius: 6px;
  margin-top: 18px;
}

.c-invoice{
  border-radius: 8px;
  background-color: #fff;
  padding: 20px 24px;
  border-left: 4px solid #ccc;
  width: 50%;
  margin-bottom: 22px;
}

.c-invoice input {
  max-width: 547px;
  width: 100%;
  border: 1px solid #ccc;
  height: 46px;
  border-radius: 5px;
  padding: 2px 12px;

}
.c-invoice label {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.c-invoice .react-datepicker-wrapper{
  width: 100%;
}
.car-detials strong {
  font-size: 18px;
}
.car-detials span {
  font-size: 18px;
  font-weight: 400;
  margin-left: 3px;
}
.car-detials {
  margin-bottom: 6px;
}