@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
.App {
  text-align: center;
}
body {
  font-family: 'Montserrat', sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {display: flex; align-items: center; justify-content: space-evenly;}
/* .sidebar { float: left; width: 15%; }
.right-list { float: left; width: 85%;} */
.car-model { display: flex; align-items: center; }
.car-wrap { border: 2px solid black;}

#loader {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  height: 100%;
}
.group.link-item:hover img, .group.link-item.active img, .filter-button:hover img , .group.link-item:hover img, .group.link-item.active img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(11%) saturate(0%) hue-rotate(50deg) brightness(105%) contrast(104%);
}
.edit-icon:hover, .delete-icon:hover {
  filter: brightness(0) saturate(100%) invert(46%) sepia(82%) saturate(3655%) hue-rotate(360deg) brightness(88%) contrast(108%);
}
@media screen and (max-width:767px) {
  .sidebar.open aside {
    width: 220px;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.data-table {
  border-radius: 10px !important;
}
input::file-selector-button {
  background: #091a36;
  color: #fff;
  border-radius: 23px;
  padding: 4px 10px;
  border: 0;
  cursor: pointer;
}


.columns {
  width: 100%;
  column-count: 3;
  gap: 0px;
}
.columns tr {
  border: 1px solid #B5B5B5;
  border-collapse: collapse;
  padding: 15px;
  width: 516px;
  border-width: 1px;
}
/* table tbody tr:nth-child(odd) {
  border-right: 0px;
  border-left: 0px;
} */
.columns>td {
  width: 150px;
  height: auto;
  padding: 5px;
  margin: 0px 5px 5px 0px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-wrap ul a.active { 
  background-color: #F29120; 
  color: #fff; 
  border-radius: 5px; 
}

.blog-list .rdt_TableBody .sc-dhKdcB {
  padding: 10px 0;
}
.edit-editor .quill {
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}
.edit-editor .quill  .ql-snow {
  border: unset;
}
.edit-editor .quill .ql-toolbar.ql-snow {
  border-bottom: 1px solid #e5e7eb;
}
.carlist .rdt_TableCell div {
  text-transform: capitalize;
}
